import {Injectable} from "@angular/core";
import {UserStateModel} from "./models/user-state.model";
import {Action, createSelector, Selector, State, StateContext} from "@ngxs/store";
import {LoadingState} from "../loading-state.enum";
import {catchError, EMPTY, tap} from "rxjs";
import {UserService} from "../../services/user/user.service";

export class GetUser {
  static readonly type = '[User] Get User';

  constructor(public userId: string) {
  }
}

@State<UserStateModel[]>({
  name: 'user',
  defaults: []
})
@Injectable({
  providedIn: 'root'
})
export class UserState {

  constructor(private userService: UserService) {
  }

  @Selector()
  public static getUser(userId: string) {
    return createSelector([UserState], (state) => {
      return state.user.find(userState => userState.userId === userId);
    });
  }

  @Action(GetUser)
  getUser(ctx: StateContext<UserStateModel[]>, action: GetUser) {
    let currentState = ctx.getState();

    if (currentState.some(user => user.userId === action.userId)) {
      return;
    }

    ctx.setState([...currentState, {
      loadingState: LoadingState.loading,
      userId: action.userId,
    }]);

    return this.userService.getUserProfile(action.userId).pipe(
      tap(user => {
        currentState = ctx.getState();

        ctx.setState(currentState.map(userState => {
          if (userState.userId === action.userId) {
            return {
              ...userState,
              loadingState: LoadingState.loaded,
              user: user.results
            };
          }

          return userState;
        }));
      }),
      catchError(() => {
        currentState = ctx.getState();

        ctx.setState(currentState.map(userState => {
          if (userState.userId === action.userId) {
            return {
              ...userState,
              loadingState: LoadingState.error,
            };
          }

          return userState;
        }));

        return EMPTY;
      })
    );
  }

}
